<!--
 * @Author: zhongxq
 * @Date: 2023-10-23 00:00:39
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-23 00:18:48
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\survey\components\RatePanel.vue
 * @Description: 打分
 *
-->
<template>
  <el-form-item :prop="question.questionId" :rules="rules">
    <template #label>
      <span class="ques-title">
        {{ index + 1 }}. {{ question.questionText }}
      </span>
    </template>
    <div class="rate-box">
      <span
        v-for="item in 10"
        :key="item"
        :class="['rate-item', { 'is-checked': Number(answer) === item }]"
        @click="handleChange(item)"
        >{{ item }}</span
      >
    </div>
    <div class="rate-desc-box">
      <span>分数很低</span>
      <span>分数很高</span>
    </div>
  </el-form-item>
</template>

<script>
import survey from "../mixins/survey";
export default {
  mixins: [survey],
  methods: {
    handleChange(item) {
      this.answer = item;
    },
  },
};
</script>

<style lang="less" scoped>
.rate-box {
  display: flex;
  width: 774/75rem;
  .rate-item {
    display: inline-block;
    width: 68/75rem;
    height: 36/75rem;
    line-height: 36/75rem;
    background: #ffffff;
    border-radius: 4/75rem;
    border: 1/75rem solid #d8dade;
    text-align: center;
    font-size: 14/75rem;
    font-weight: 400;
    color: #212122;
    cursor: pointer;
    & + .rate-item {
      margin-left: 10/75rem;
    }
    &:hover,
    &.is-checked {
      color: #ffffff;
      background: #f9680d;
      border-color: transparent;
    }
  }
}
.rate-desc-box {
  width: 774/75rem;
  display: flex;
  justify-content: space-between;
  margin-top: 20/75rem;
  color: #909399;
  font-size: 14/75rem;
  line-height: 14/75rem;
}
</style>
