<!--
 * @Author: zhongxq
 * @Date: 2023-10-22 22:50:39
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-23 00:38:50
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\survey\components\RadioPanel.vue
 * @Description: 单选框
 *
-->
<template>
  <el-form-item :prop="question.questionId" :rules="rules">
    <template #label>
      <span class="ques-title">
        {{ index + 1 }}. {{ question.questionText }}
      </span>
    </template>
    <el-radio-group v-model="answer">
      <el-radio
        v-for="item in choices"
        :key="item.choiceId"
        :label="item.choiceId"
        >{{ item.choiceText }}</el-radio
      >
    </el-radio-group>
  </el-form-item>
</template>

<script>
import survey from "../mixins/survey";
export default {
  mixins: [survey],
};
</script>

<style lang="less" scoped>
::v-deep(.el-radio-group) {
  .el-radio {
    display: flex;
    margin: 0;
    & + .el-radio {
      margin-top: 15/75rem;
    }
    .el-radio__label {
      color: #606266;
      white-space: normal;
      padding-left: 10/75rem;
    }
    .el-radio__input {
      margin-top: 1/75rem;
    }
  }
  .el-radio__inner {
    width: 26/75rem;
    height: 26/75rem;
  }
  .el-radio__input.is-checked {
    .el-radio__inner {
      background: #ffffff;
      border-color: #707070;
      &::after {
        background: #f9680d;
        width: 18/75rem;
        height: 18/75rem;
      }
    }
    & + .el-radio__label {
      color: #606266;
    }
  }
}
</style>
