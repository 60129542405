<!--
 * @Author: zhongxq
 * @Date: 2023-10-22 22:28:18
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-23 15:27:08
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\survey\components\InputPanel.vue
 * @Description: 输入框
 *
-->
<template>
  <el-form-item :prop="question.questionId" :rules="rules">
    <template #label>
      <span class="ques-title">
        {{ index + 1 }}. {{ question.questionText }}
      </span>
    </template>
    <el-input
      v-model="answer"
      type="textarea"
      autosize
      placeholder="请输入"
      style="width: 6.28rem"
    />
  </el-form-item>
</template>

<script>
import survey from "../mixins/survey";
export default {
  mixins: [survey],
};
</script>

<style lang="less" scoped>
::v-deep(.el-textarea) {
  textarea {
    min-height: 30/75rem !important;
  }
}
</style>
