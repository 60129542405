<template>
  <div class="fixed-page-mobile survey-index-mobile">
    <div class="container">
      <c-image
        class="head-img"
        :src="require('@/assets/img/performance/survey-bg.png')"
      />
      <div class="survey-body">
        <h1>{{ detail.title }}</h1>
        <p class="desc">
          {{ detail.description }}
        </p>
        <el-form
          :model="form"
          ref="formRef"
          class="survey-form"
          label-position="top"
        >
          <component
            v-for="(item, index) in detail.questions"
            v-model="form[item.questionId]"
            :key="item.questionId"
            :index="index"
            :question="item"
            :is="`${item.questionType}-panel`"
            :ref="`ref${item.questionId}`"
          />
        </el-form>
        <button class="submit-btn" @click="handleSubmit">
          <i v-if="loading" class="el-icon-loading" />
          提交
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getSurveyDetail, submitSurvey } from "@/service/survey";
import InputPanel from "./components/InputPanel.vue";
import RadioPanel from "./components/RadioPanel.vue";
import CheckboxPanel from "./components/CheckboxPanel.vue";
import SelectPanel from "./components/SelectPanel.vue";
import RatePanel from "./components/RatePanel.vue";
export default {
  components: {
    InputPanel,
    RadioPanel,
    CheckboxPanel,
    SelectPanel,
    RatePanel,
  },
  data() {
    return {
      id: this.$route.params.id,
      form: {},
      detail: {},
      loading: false,
      typeMap: {
        单选: "radio",
        多选: "checkbox",
        文本: "input",
        评分: "rate",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const res = await getSurveyDetail(this.id);
        const data = res.data || {};
        data.questions = data.questions?.map((item) => {
          return {
            ...item,
            questionType: this.typeMap[item.questionType] || item.questionType,
          };
        });
        this.detail = data;
      } catch (e) {
        console.log(e);
      }
    },
    handleSubmit() {
      console.log(this.form);
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            const answers = this.detail.questions.map((item) => {
              let answer = {
                questionId: item.questionId,
                sort: item.sort,
              };
              if (item.questionType === "input") {
                answer.answerText = this.form[item.questionId];
              }
              if (["checkbox", "radio", "select"].includes(item.questionType)) {
                const dom = this.$refs[`ref${item.questionId}`][0];
                answer.selectedChoice = dom.getLabel();
              }
              if (["radio", "select"].includes(item.questionType)) {
                answer.answerId = this.form[item.questionId];
              }
              return answer;
            });
            const params = {
              answers,
              participant: {
                surveyId: this.detail.surveyId,
              },
            };
            await submitSurvey(params);
            this.$message.success("提交成功！");
            this.$router.back();
          } catch (e) {
            console.log(e);
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="less">
.survey-index-mobile {
  h1,
  p {
    padding: 0;
    margin: 0;
  }
  .container {
    background-color: #ffffff;
    margin: auto;
    width: 100%;
    box-sizing: border-box;
    .el-radio__label,
    .el-checkbox__label,
    textarea {
      font-size: 22/75rem;
      line-height: 26/75rem;
    }
    .head-img {
      width: 100%;
      height: auto;
    }
    .survey-body {
      padding: 50/75rem 30/75rem;
      box-sizing: border-box;
      text-align: left;
      h1 {
        font-size: 40/75rem;
        font-weight: 900;
        color: #000000;
        text-align: center;
        margin-bottom: 30/75rem;
      }
      .desc {
        font-weight: 900;
        font-size: 28/75rem;
        line-height: 32/75rem;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 30/75rem;
      }
      .survey-form {
        margin-bottom: 50/75rem;

        .el-form-item__label {
          color: #212122;
          font-weight: 800;
          font-size: 26/75rem;
          line-height: 30/75rem;
          padding: 10/75rem 0 20/75rem;
          &::before {
            margin: 0;
          }
        }
        .el-form-item__content {
          margin-left: 40/75rem;
        }
      }
      .submit-btn {
        display: block;
        margin: 50/75rem auto 0;
        width: 100%;
        height: 54/75rem;
        background: #f9680d;
        border-radius: 4/75rem;
        color: #ffffff;
        border: unset;
        text-align: center;
        line-height: 54/75rem;
        cursor: pointer;
      }
    }
  }
}
</style>
