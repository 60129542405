<!--
 * @Author: zhongxq
 * @Date: 2023-10-22 23:51:54
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-23 00:01:53
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\survey\components\SelectPanel.vue
 * @Description: 选择框
 *
-->
<template>
  <el-form-item :prop="question.questionId" :rules="rules">
    <template #label>
      <span class="ques-title">
        {{ index + 1 }}. {{ question.questionText }}
      </span>
    </template>
    <el-select
      v-model="answer"
      style="width: 6.28rem"
      clearable
      placeholder="请选择"
    >
      <el-option
        v-for="item in choices"
        :key="item.choiceId"
        :label="item.choiceText"
        :value="item.choiceId"
        >{{ item.choiceText }}</el-option
      >
    </el-select>
  </el-form-item>
</template>

<script>
import survey from "../mixins/survey";
export default {
  mixins: [survey],
};
</script>

<style lang="less" scoped></style>
